import axios from 'axios'
import promiseBuilder from '@/promises/promiseBuilder'
import { searchFlightsService } from '../services'

const api = (configUrls) => ({
  install (Vue) {
    const apiParams = {
      api: axios,
      promiseBuilder,
      getUrl: configUrls?.apiUrl,
      postUrl: configUrls?.apiPost,
      baseUrl: configUrls?.apiBaseUrl
    }

    Vue.prototype.$api = {
      searchFlights: searchFlightsService(apiParams)
    }
  }
})

export default api
