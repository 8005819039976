<template>
  <div class="multi">
    <article class="multicity__Widget">
      <Multicity
        :booking-data="$store.getters.getBookingData"
        :display-target="$store.getters.displayTarget"
      />
    </article>
  </div>
</template>

<script>
import Multicity from 'core-lib/components/searcher/multicity/Multicity.vue'
export default {
  name: 'SearchForm',
  components: {
    Multicity
  }
}
</script>
<style lang="sass">
.multi
  width: 100%
.multicity__Widget
  background: var(--color-surface)
  border: 1px solid var(--color-overlay-20)
  border-bottom: 8px solid var(--color-primary)
  border-radius: var(--b-radius-lg)
  margin: 0 auto
  max-width: 90%
  margin-top: 3rem
</style>
