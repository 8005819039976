<template>
  <div class="widgetSearchFlight">
    <Searcher
      v-if="bookingData"
      :bookingData="bookingData"
    />
    <div v-if="bookingData && bookingData.cookieMessage.enable === true">
      <cookie-message :buttons_enable="bookingData.cookieMessage.confirmation_button" @close-alert-component="closeDiv">
      </cookie-message>
    </div>
    <div v-if="hasTemporaryMessage" >
      <temporary-message  @close-alert-component="closeDiv(true)" :details="bookingData.temporaryMessage.details">
      </temporary-message>
    </div>
  </div>
</template>
<script>
// import axios from 'axios'
import cookieMessage from '../components/cookieMessage'
import temporaryMessage from '../components/temporaryMessage'
import { setLanguageAsync } from 'core/plugins/I18n'
import { Validator } from 'vee-validate'
import Searcher from 'core-lib/components/searcher/Searcher.vue'

export default {
  name: 'WidgetSearchFlight',
  data () {
    return {
      bookingData: null,
      showAlert: true,
      hasTemporaryMessage: false
    }
  },
  inject: ['$validator'],
  components: {
    Searcher,
    cookieMessage,
    temporaryMessage
  },
  computed: {
    getLang () {
      var search = window.location.search.replace('?', '').split('&').find((i) => i.includes('lang='))
      return (search ? search.replace('lang=', '') : navigator.language || navigator.userLanguage)
    }
  },
  beforeMount () {
    this.handleTranslations()
    this.$store.commit('setShowHeader', false)
  },
  mounted () {
    // axios.get(this.$store.getters.getConfigUrls.apiUrl + 'configs/')
    this.$api.searchFlights.getConfig(this.$store.getters.getConfigUrls.apiUrl)
      .then((response) => {
        const enabledRoutes = {
          information: response.data.response.simple_routes.information,
          outgoin: response.data.response.simple_routes.routes,
          return: response.data.response.simple_routes.reverse_routes
        }
        let countrySetting = response.data.response.country_settings.find(x => x.default)
        this.bookingData = {
          roundTrip: response.data.response.journey_type.round_trip,
          roundTripDefault: response.data.response.journey_type.round_trip_default,
          oneWay: response.data.response.journey_type.one_way,
          oneWayDefault: response.data.response.journey_type.one_way_default,
          passengers: response.data.response.passenger_types,
          countrySettings: response.data.response.country_settings,
          countrySetting: countrySetting,
          display_logics: response.data.response.display_logics,
          routes: response.data.response.routes,
          cabin: response.data.response.cabin,
          cookieMessage: response.data.response.privacy_policy,
          temporaryMessage: response.data.response.temporary_message,
          promo: response.data.response.promo,
          session_key: this.$store.getters.getSessionId,
          searchParameters: response.data.response.search_parameters,
          tourcodeList: response.data.response.tourcode_list,
          route_pos_assign_data: response.data.response.route_pos_assign_data,
          enabledRoutes: enabledRoutes
        }
        this.setInitTemporaryMessage()
        // Norman Lo guardo para usarlo en FlightsResult
        this.$store.commit('setSearchFlightConfig', this.bookingData)
      })
      .catch((error) => {
        console.log('error get config backend', error)
        // this.$router.push('internal-error')
      })
  },
  methods: {
    handleTranslations: async function () {
      let lang = this.getLang
      this.$i18n.locale = lang
      this.$store.commit('setLanguage', lang)
      this.$validator.locale = lang
      await this.getTranslations(lang)
    },
    getTranslations: async function (lang) {
      let langExceptions = {
        'pr': 'pr_PT'
      }
      await setLanguageAsync(this, lang)
      try {
        Validator.localize(lang, require('vee-validate/dist/locale/' + (langExceptions[lang] ? langExceptions[lang] : lang)))
      } catch (error) {
        console.error(error)
      }
    },
    closeDiv (isTemporary) {
      if (isTemporary) {
        this.hasTemporaryMessage = false
      } else {
        this.bookingData.cookieMessage.enable = false
      }
    },
    setInitTemporaryMessage () {
      this.hasTemporaryMessage = this.bookingData.temporaryMessage.details.find(z => z.enable === true)
    }
  }
}
</script>
<style lang="sass" scoped>
@import '~core/assets/sass/double-buttons.sass'
.widgetSearchFlight
  width: 100%
  max-width: 1400px
  margin: 0 auto
</style>
