<template>
  <div class="inputTag">
    <label v-show="visibleLabel" @click="modalVisible = true" id="label" class="inputTag__label">{{ $t('passengers') }}*</label>
    <div class="inputTag__group">
      <input
        v-if="resultVisible"
        @keydown.enter="modalVisible = true"
        @click="modalVisible = true"
        readonly
        v-validate="'required'"
        :data-vv-as="$t('passengers')"
        class="inputTag__input"
        type="text"
        :value="formatValue"
        aria-label="passengers"
        aria-describedby="basic-addon1"
        tabindex="0"
        id="passenger-class-btn"
        name="passengers"
      >
    </div>
    <span class="inputTag__feedback">{{ errors.first('passengers') }}</span>
    <transition name="modalfadein" mode="in-out">
      <div class="searchFlight__passengers" v-show="modalVisible">
        <form class="searchFlight__passengersForm">
          <div class="searchFlight__passengersContainer">

            <div
              class="double-button"
              v-for="(pax, index) in passengersEnabled"
              :key="'pax' + index"
            >
              <div class="double-button__label">
                <!-- <p>{{ $t(`${passenger.labels[0].label}`) }}</p> -->
                <span>{{ $t(`${pax.ptc}_quantity`) }}</span>
                <span v-if="errors.first(pax.ptc)"
                  class="searchFlight__error"
                >
                  {{ errors.first(pax.ptc) }}
                </span>
              </div>
              <div class="input-group double-button__container">
                <div class="input-group-prepend">
                  <button
                    :id="`${pax.ptc}Minus`"
                    :ref="`${pax.code}Minus`"
                    class="btn btn-outline-gray"
                    tabindex="0"
                    type="button"
                    :disabled="passengerStatus[pax.ptc] === pax.min_quantity"
                    @click="changeCount(pax, -1)"
                  >
                    <i class="fas fa-minus"></i>
                  </button>
                </div>
                <input
                  class="form-control"
                  v-model="passengerStatus[pax.ptc]"
                  :ref="`pc${pax.ptc}`"
                  :id="`pc-${pax.ptc}`"
                  type="text"
                  :name="pax.ptc"
                  disabled="disabled"
                  :max="pax.max_quantity"
                  placeholder="0"
                  aria-label="Recipient's username"
                  aria-describedby="basic-addon2"
                  tabindex="0"
                >
                <div class="input-group-append">
                  <button
                    :id="`${pax.ptc}Plus`"
                    class="btn btn-outline-gray"
                    tabindex="0"
                    type="button"
                    @click="changeCount(pax, 1)"
                    :disabled="passengerStatus[pax.ptc] === pax.max_quantity"
                  >
                    <i class="fas fa-plus"></i>
                  </button>
                </div>
              </div>

            </div>
          </div>
          <div class="searchFlight__cabinContainer">
            <div class="inputSelect"
            >
              <label class="inputSelect__label" for="search_cabin">{{$t('cabin')}}</label>
              <div class="inputSelect__group">
                <div class="inputSelect__icon">
                  <i class="fas fa-star"></i>
                </div>
                <select
                  class="inputSelect__input"
                  ref="cabins"
                  v-model="cabin"
                  id="search_cabin">
                  <option
                    v-for="option in getCabins"
                    :value="option"
                    :key="option"
                  >
                    {{ $t(`${option}`)}}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div class="searchFlight__formFooter">
            <button
              type="button"
              @click="close()"
              class="btn btn-block"
              tab-index="11"
              id="cancel_pc"
              ref="cancelButton"
            >{{ $t('cancel') }}</button>
            <button
              class="btn btn-block btn-primary"
              id="add-pc"
              tab-index="10"
              type="button"
              ref="doneButton"
              :disabled="totalAdults === 0"
              @click="save"
            >{{ $t('done') }}</button>
          </div>
        </form>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'Passengers',
  props: {
    passengers: { required: true },
    cabins: { required: true },
    value: { required: false }
  },
  inject: ['$validator'],
  data () {
    return {
      resultVisible: false,
      modalVisible: false,
      visibleLabel: true,
      passengerStatus: null,
      cabin: '',
      passengersEnabledOriginal: null
    }
  },
  mounted () {
    this.$emit('input', {
      counts: {
        adt: this.totalAdults,
        chd: this.totalChild,
        inf: this.totalInfants
      },
      cabin: this.cabin
    })
  },
  methods: {
    initializeCounts: function () {
      let passengerStatus = {}
      // hacemos esto para que no pase por referencia
      this.passengersEnabledOriginal = JSON.parse(JSON.stringify(this.passengersEnabled))
      this.passengersEnabled.forEach(passenger => {
        passengerStatus[passenger.ptc] = 0
        if (passenger.code === 'ADT' && passenger.ptc === 'ADT') {
          console.log(passenger.default_quantity)
          if (passenger.default_quantity === 0) {
            console.log('ptc = 1')
            passengerStatus[passenger.ptc] = 1
          } else {
            console.log('ptc = default_quantity')
            passengerStatus[passenger.ptc] = passenger.default_quantity
          }
        }
      })
      let i
      for (i in this.passengersEnabled) {
        if (this.passengersEnabled[i]['code'] === 'ADT' && this.passengersEnabled[i]['ptc'] === 'ADT') {
          this.passengersEnabled[i]['min_quantity'] = 1
        } else {
          this.passengersEnabled[i]['min_quantity'] = 0
        }
      }
      this.passengerStatus = passengerStatus
    },
    totalByPax: function (type) {
      let total = 0
      this.passengersEnabled.filter(x => x.code === type).forEach(pax => {
        total += this.passengerStatus[pax.ptc]
      })
      return total
    },
    close: function () {
      this.errors.clear()
      this.modalVisible = false
      this.resultVisible = false
      this.visibleLabel = true
      this.$emit('input', {
        counts: {
          adt: 1,
          chd: 0,
          inf: 0
        },
        cabin: this.cabin
      })
      this.initializeCounts()
    },
    save: function () {
      this.errors.clear()
      this.modalVisible = false
      this.resultVisible = true
      this.visibleLabel = false
      this.$emit('input', {
        counts: {
          adt: this.totalAdults,
          chd: this.totalChild,
          inf: this.totalInfants
        },
        cabin: this.cabin
      })
    },
    verifyQuanty: function () {
      let cantAdt = 0
      let cantAdtAdt = 0
      let maxQuantityOriginal = 0
      let passengerOrig = this.passengersEnabledOriginal
      this.passengersEnabled.filter(x => x.code === 'ADT' && x.ptc !== 'ADT').forEach(x => {
        cantAdt += this.passengerStatus[x.ptc]
      })
      this.passengersEnabled.filter(x => x.code === 'ADT' && x.ptc === 'ADT').forEach(x => {
        cantAdtAdt += this.passengerStatus[x.ptc]
      })
      if (cantAdt === 0 && cantAdtAdt === 0) {
        this.passengersEnabled.filter(x => x.code === 'ADT' && x.ptc === 'ADT').forEach(x => {
          if (x.default_quantity === 0) {
            this.passengerStatus[x.ptc] = 1
          } else {
            this.passengerStatus[x.ptc] = x.default_quantity
          }
        })
      }
      // Si total de Inf == total de Adt no debo permitir que borren adultos
      if (this.totalAdults === this.totalInfants) {
        this.passengersEnabled.filter(x => x.code === 'ADT').forEach(x => {
          x.min_quantity = this.passengerStatus[x.ptc]
        })
      } else {
        this.passengersEnabled.filter(x => x.code === 'ADT' && x.ptc === 'ADT').forEach(x => {
          x.min_quantity = 1
        })
        this.passengersEnabled.filter(x => x.code === 'ADT' && x.ptc !== 'ADT').forEach(x => {
          x.min_quantity = 0
        })
      }
      if (this.totalAdults + this.totalChild >= 9) {
        this.passengersEnabled.filter(x => x.code !== 'INF').forEach(x => {
          x.max_quantity = this.passengerStatus[x.ptc]
        })
      } else {
        this.passengersEnabled.filter(x => x.code !== 'INF').forEach(x => {
          passengerOrig.filter(y => y.id === x.id).forEach(y => {
            maxQuantityOriginal = y.max_quantity
          })
          x.max_quantity = maxQuantityOriginal
        })
      }
      if (this.totalInfants >= this.totalAdults) {
        this.passengersEnabled.filter(x => x.code === 'INF').forEach(x => {
          x.max_quantity = this.passengerStatus[x.ptc]
        })
      } else {
        this.passengersEnabled.filter(x => x.code === 'INF').forEach(x => {
          passengerOrig.filter(y => y.id === x.id).forEach(y => {
            maxQuantityOriginal = y.max_quantity
          })
          if (this.totalAdults > maxQuantityOriginal) {
            x.max_quantity = maxQuantityOriginal
          } else {
            x.max_quantity = this.totalAdults
          }
        })
        let i
        for (i in this.passengersEnabled) {
          if (this.passengersEnabled[i]['code'] === 'ADT' && this.passengersEnabled[i]['ptc'] === 'ADT' && cantAdt === 0) {
            this.passengersEnabled[i]['min_quantity'] = 1
          } else {
            this.passengersEnabled[i]['min_quantity'] = 0
          }
        }
      }
    },
    changeCount: function (pax, operation) {
      this.errors.clear()
      if (operation === 1) {
        /** if is sum **/
        if (this.totalPassengers >= 9 && pax.code !== 'INF') {
          this.errors.add({
            field: pax.ptc,
            msg: this.$t('errors.max_9_pax')
          })
        } else if (pax.code === 'INF' && this.totalAdults <= this.totalInfants) {
          this.errors.add({
            field: pax.ptc,
            msg: this.$t('errors.one_inf_per_adult')
          })
        } else if (pax.code === 'CHD' && this.totalAdults === 0) {
          this.errors.add({
            field: pax.ptc,
            msg: this.$t('errors.at_least_one_adult')
          })
        } else {
          this.passengerStatus[pax.ptc] += 1
        }
        this.passengersEnabled.filter(x => x.code === 'INF').forEach(x => {
          x.max_quantity = this.totalAdults
        })
      } else {
        /** if is diff **/
        if (pax.code === 'ADT') {
          if (this.totalAdults === 1) {
            this.passengersEnabled.filter(x => x.code === 'CHD').forEach(x => {
              this.passengerStatus[x.ptc] = 0
            })
          }
          if (this.totalAdults === this.passengerStatus['INF']) {
            this.passengersEnabled.filter(x => x.code === 'INF').forEach(x => {
              if (this.passengerStatus[x.ptc] > 0) {
                this.passengerStatus[x.ptc] -= 1
              }
            })
          }
        }
        this.passengerStatus[pax.ptc] -= 1
        this.passengersEnabled.filter(x => x.code === 'INF').forEach(x => {
          x.max_quantity = this.totalAdults
        })
      }
      // if (pax.code === 'ADT') {
      this.verifyQuanty()
      // }
    }
  },
  computed: {
    formatValue: function () {
      let data = this.value.counts
      return this.value ? `${this.$t('ADT_quantity')}: ${data.adt} - ${this.$t('CHD_quantity')}: ${data.chd} - ${this.$t('INF_quantity')}: ${data.inf}` : ''
    },
    getCabins: function () {
      let cabins = []
      Object.keys(this.cabins).forEach(key => {
        if (key.includes('_default')) {
          let cabinName = key.replace('_default', '')
          if (this.cabins[cabinName]) {
            cabins.push(cabinName)
          }
        }
      })
      return cabins
    },
    passengersEnabled: function () {
      return this.passengers.filter(x => x.enable)
    },
    totalPassengers: function () {
      return this.totalAdults + this.totalChild
    },
    totalAdults: function () {
      return this.totalByPax('ADT')
    },
    totalInfants: function () {
      return this.totalByPax('INF')
    },
    totalChild: function () {
      return this.totalByPax('CHD')
    }
  },
  created () {
    this.initializeCounts()
    this.cabin = this.getCabins.find(key => this.cabins[key + '_default'] === true)
  }
}
</script>
