<template>
  <div
    id="app"
    class="appContainer"
    :class="{
      '--summary-active': $store.state.systemStore.summaryBehavior.showSummary
    }"
  >
    <AppCore
      :appName="getAppName"
      :onlyLanguage="true"
      :showHeader="$store.getters.getShowHeader"
      :summaryBehavior="$store.state.systemStore.summaryBehavior"
      :onpopstateEnabled="false"
      :hideSummaryToggle="true"
      ref="appCore"
      @config-ready="loadApi"
    ></AppCore>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Vue from 'vue'
import api from './plugins/api'
export default {
  name: 'App',
  data () {
    return {
      cacheFlow: {}
    }
  },
  computed: {
    ...mapGetters([
      'getConfigUrls',
      'getAppName'
    ])
  },
  watch: {
    getConfigUrls: {
      handler (val) {
        if (this.$route.path.includes('b2b-login')) {
          let css = document.createElement('link')
          let main = document.createElement('script')
          let md5 = document.createElement('script')
          css.setAttribute('href', val.b2b.css)
          css.setAttribute('rel', 'stylesheet')
          document.head.appendChild(css)
          main.setAttribute('src', val.b2b.main)
          document.head.appendChild(main)
          md5.setAttribute('src', val.b2b.md5)
          document.head.appendChild(md5)
        }
      },
      immediate: false
    },
    getAppName: {
      handler: async function (appName) {
        this.$store.commit('setFlow', null)
        if (Object.keys(this.cacheFlow).includes(appName)) {
          console.log('session updated')
          await this.$store.dispatch('setSession', this.cacheFlow[appName])
          this.$refs['appCore'].setAxiosHeaders()
        } else {
          this.$refs.appCore.getSession().then(() => {
            this.cacheFlow[appName] = {
              'flow': this.$store.getters.getFlow,
              'session_key': this.$store.getters.getSessionId
            }
          })
        }
      }
    }
  },
  mounted () {
    // solo usado para spa searchflight
    this.setCustomPathList()
    this.$store.commit('setshowBackButton', false)
    this.$store.commit('summaryBehaviorHandler', { state: 'absolute', continueButton: false, showSummary: false },
      { module: 'systemStore' })
    this.$store.commit('clearGASearchFlight')
  },
  components: {
    AppCore: () => import(/* webpackChunkName: "app-core" */'core/components/AppCore')
  },
  methods: {
    loadApi () {
      Vue.use(api(this.$store.getters.getConfigUrls))
    },
    setCustomPathList () {
      try {
        let pathList = this.$router.options.routes.filter((r) => {
          return r.component.data
        }).map((d) => {
          return d.path
        })
        this.$store.commit('setPathList', pathList)
      } catch (err) {
        console.error(err)
      }
    },
    /**
     * Set Session storage with query param origin and/or destination to display widget with defaults airports
     * &origin=AEP&destination=COR
     * @param queryRoute  this.$route.query
     */
    setOriginDestinationFromQueryRoute: function (queryRoute) {
      let origin = queryRoute.origin ? queryRoute.origin.substring(0, 3).toUpperCase() : ''
      let destination = queryRoute.destination ? queryRoute.destination.substring(0, 3).toUpperCase() : ''
      let search = {
        origin: origin,
        destination: destination
      }
      this.$store.commit('setSearchData', search)
    },
    /**
     * Set Session storage with query param pos to display desired fop id's in CountrySettings component
     * &pos=3,4,5
     */
    setPosIdFromQueryRoute: function (queryRoute) {
      let posIdFromQr = queryRoute.pos ? queryRoute.pos : []
      let posId = {
        posId: posIdFromQr
      }
      this.$store.commit('setPosId', posId)
    }
  },
  created () {
    this.$store.commit('setPosId', {})
    this.$store.commit('setSearchData', null)
    // Comento Norman, no existe la mutacion
    // this.$store.commit('setCacheLanguagesList', null)
    this.$store.commit('setBookingPrices', {
      purchasePrice: null,
      summaryPrice: null
    })
    this.$store.commit('setCountrySetting', {})
    this.$store.commit('setflowWebCkInFromMMB', false)
    const queryRoute = this.$route.query
    this.$store.dispatch('setInitWidget')
    if (queryRoute.module) {
      this.$store.commit('setModuleWidget', queryRoute.module)
    }
    const appName = queryRoute.module || 'ecommerce'
    this.$store.commit('setAppName', appName)
    if (queryRoute.lang) {
      this.$i18n.locale = queryRoute.lang
      this.$store.commit('setLanguage', queryRoute.lang)
    }
    if (queryRoute.no_tab) {
      this.$store.commit('setNoTabWidget', queryRoute.no_tab === 'true')
    }
    if (queryRoute.origin || queryRoute.destination) {
      this.setOriginDestinationFromQueryRoute(queryRoute)
    }
    if (queryRoute.pos) {
      this.setPosIdFromQueryRoute(queryRoute)
    }
    // Add Norman 2024-08-14 clear store
    this.$store.commit('setExchangeNewPriceDetail', {})
    this.$store.commit('setExchangeOriginalFlights', [])
    this.$store.commit('setSelectedDatesToExchange', {})
    this.$store.commit('setSelectedFlightsToExchange', [])
    this.$store.commit('setExtraServicesMovementChecker', {})
    this.$store.commit('setExchangeDepartureInfo', {})
    this.$store.commit('setExtraServicesByPassengerSegment', {})
  }
}
</script>
<style lang="sass">
.--is-widget
  .loadingHolder
    background: transparent !important
body
  justify-content: center
  align-items: center
  background: transparent
.appContainer
  width: 100%
.userAccount__modal
  background-color: var(--color-surface)
  color: var(--color-onSurface)
</style>
<!-- Progress Bar -->
<style>
  .--has-fancy {
    position: relative;
  }
  .fancyProgress {
    position: absolute;
    height: 3px;
    display: block;
    width: 100%;
    background-color: #9fb9e4;
    border-radius: 2px;
    background-clip: padding-box;
    bottom: 0;
    overflow: hidden;
  }

  .fancyProgress .determinate {
    position: absolute;
    background-color: inherit;
    top: 0;
    bottom: 0;
    background-color: var(--color-primary);
    transition: width .3s linear;
  }

  .fancyProgress .indeterminate {
    background-color: var(--color-primary);
  }
  .fancyProgress .indeterminate:before {
    content: '';
    position: absolute;
    background-color: inherit;
    top: 0;
    left: 0;
    bottom: 0;
    will-change: left, right;
    animation: indeterminate 2.1s cubic-bezier(0.65, 0.815, 0.735, 0.395) infinite;
  }
  .fancyProgress .indeterminate:after {
    content: '';
    position: absolute;
    background-color: inherit;
    top: 0;
    left: 0;
    bottom: 0;
    will-change: left, right;
    animation: indeterminate-short 2.1s cubic-bezier(0.165, 0.84, 0.44, 1) infinite;
    animation-delay: 1.15s;
  }
  @keyframes indeterminate {
    0% {
      left: -35%;
      right: 100%;
    }
    60% {
      left: 100%;
      right: -90%;
    }
    100% {
      left: 100%;
      right: -90%;
    }
  }
  @keyframes indeterminate-short {
    0% {
      left: -200%;
      right: 100%;
    }
    60% {
      left: 107%;
      right: -8%;
    }
    100% {
      left: 107%;
      right: -8%;
    }
  }
</style>
