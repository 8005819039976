<template>
  <div class="flightType__container">
    <div class="inputSelect__group --sm">
      <select class="inputSelect__input" v-model="val" @change="choice">
        <option value="one_way" v-if="bookingData.oneWay">{{ $t('one_way') }}</option>
        <option value="round_trip" v-if="bookingData.roundTrip">{{ $t('round_trip') }}</option>
        <!--<option value="multi_destination">Multidestino</option>-->
      </select>
      <i class="inputSelect__icon fas fa-caret-down"></i>
    </div>
  </div>
</template>

<script>
export default {
  name: 'flightType',
  data () {
    return {
      val: null
    }
  },
  props: {
    bookingData: {
      required: true
    },
    roundTrip: {
      required: true
    }
  },
  mounted () {
    this.val = this.roundTrip ? 'round_trip' : 'one_way'
  },
  methods: {
    choice () {
      this.$emit('click-round-trip', (this.val === 'round_trip'))
    }
  }
}
</script>
