<template>
  <div class="widgetBooking__main" v-show="!externalData">
    <div class="widgetBooking__content" v-if="loadedTabs">
      <ul class="widgetBooking__tab" v-if="showTabs">
        <li
          v-for="form in enabledFormsListSorted"
          :key="form.name"
          class="widgetBooking__tabItem"
          v-show="!form.fail"
          :class="{ active: isActiveTab(form.name) }"
        >
          <a
            class="widgetBooking__tabLink"
            @click.prevent="setActiveTab(form.name)"
            :href="'#'+form.name"
            @click="manageFormApp(form)"
          >
            {{ $t(form.label) }}
          </a>
        </li>
      </ul>
      <div class="widgetBooking__tabContent tab-content">
        <div
          v-for="form in enabledFormsListSorted"
          :key="form.name"
          :id="form.name"
          class="tab-pane fade"
          :class="{ 'active show': isActiveTab(form.name) }"
        >
          <div
            v-if="form.component && !form.embeddable"
          >
            <component
              :is="form.component"
              :bookingData="form.data"
              :scope="form.name"
              :baseUrl="getBaseUrl(form)"
              :external-data="externalData"
            />
          </div>
          <div v-else>
            <iframe
              v-if="form.url"
              frameborder="0"
              class="embeddables"
              :src="getCheckinUrl()"
              :height="form.height"
              :width="form.width"
              :id="`${form.name}_iframe`"
            ></iframe>
            <!--Provisional, pero da la idea de como trabajar los formularios externos-->
          </div>
        </div>
      </div>
    </div>
    <div v-if="bookingData && bookingData.cookieMessage.enable === true">
      <cookie-message
        :buttons_enable="bookingData.cookieMessage.confirmation_button"
        @close-alert-component="closeDiv"
      ></cookie-message>
    </div>
    <div v-if="this.hasTemporaryMessage" >
      <temporary-message
        @close-alert-component="closeDiv(true)"
        :buttons_enable="bookingData.cookieMessage.confirmation_button"
        :details="bookingData.temporaryMessage.details"
      ></temporary-message>
    </div>
  </div>
</template>

<script>
// import axios from 'axios'
import Searcher from 'core-lib/components/searcher/Searcher.vue'
import RetrieveForm from '../components/RetrieveForm'
import cookieMessage from '../components/cookieMessage'
import temporaryMessage from '../components/temporaryMessage'

export default {
  name: 'WidgetBooking',
  props: {
    externalData: {
      required: false
    }
  },
  data () {
    return {
      bookingData: null,
      showAlert: true,
      hasTemporaryMessage: false,
      applicationsList: [],
      forms: [
        {
          app_name: 'ecommerce',
          name: 'searchflight',
          label: 'searchflight_tab_label',
          component: 'Searcher',
          embeddable: false,
          order: 1
        },
        {
          app_name: 'webcheckin',
          name: 'webcheckin',
          label: 'retrieve_booking_web_checkin_tab_label',
          component: 'RetrieveForm',
          embeddable: false,
          order: 2
        },
        {
          app_name: 'manage',
          name: 'managebooking',
          label: 'retrieve_booking_manage_booking_tab_label',
          component: 'RetrieveForm',
          embeddable: false,
          order: 3
        },
        {
          app_name: 'webcheckin2',
          name: 'webcheckin2',
          label: 'webcheckin2_tab_label',
          embeddable: true,
          height: 'auto',
          width: '100%',
          // Temporal, hasta que resolvamos las propiedades de las aplicaciones como se debe
          url: 'para definir en backoffice...',
          order: 4
        }
        /* {
          name: 'kibe',
          label: 'kibe_tab_label',
          embeddable: true,
          url: 'https://kibe-xx.kiusys.net/widget/',
          order: 5
        } */
      ],
      activeItem: null,
      loadedTabs: false
    }
  },
  inject: ['$validator'],
  computed: {
    showTabs () {
      var store = this.$store.getters
      let res = true
      if (this.applicationsList.includes(store.getModuleWidget)) {
        res = !store.getNoTabWidget
      }
      return res
    },
    enabledFormsListSorted () {
      const paramModule = this.$store.getters.getModuleWidget
      let appList = this.applicationsList
      if (paramModule && this.applicationsList.includes(paramModule)) {
        appList = [paramModule]
        var key = this.forms.findIndex(k => k.app_name === paramModule)
        this.setActiveTab(this.forms[key].name)
        this.manageFormApp(this.forms[key])
      }
      return this.forms.filter(e => {
        return appList.includes(e.app_name)
      }).sort((a, b) => {
        return a.order - b.order
      })
    }
  },
  components: {
    Searcher,
    RetrieveForm,
    cookieMessage,
    temporaryMessage
  },
  beforeMount () {
    const lang = this.$store.getters.getLanguage
    this.$i18n.locale = lang
    this.$validator.locale = lang
    // evitar esto
    this.getApplicationsList()
  },
  methods: {
    async buildConfigHandlerFunction (element) {
      try {
        await this[`get${this.capitalize(element.name)}Config`](element)
      } catch (e) {
        this.setLoadedTabs(element)
      }
    },
    getCheckinUrl () {
      // esto es solo para emular lo que debe venir del backoffice
      var origin = window.location.origin
      var env = (
        (origin.includes('stage') ? 'stage-' : origin.includes('dev') ||
        origin.includes('qa') || origin.includes('local') ? 'qa-' : '')
      )
      return `https://wc2-${env}${this.$store.getters.getCarrier}.kiusys.net/widget/`
    },
    manageFormApp (form) {
      if (form.app_name) {
        this.$store.commit('setAppName', form.app_name)
      }
    },
    handleFormList () {
      this.enabledFormsListSorted.map((element) => {
        if (element.order === 1) {
          this.activeItem = element.name
          this.manageFormApp(element)
        }
        if (!element.embeddable && element.component) {
          this.buildConfigHandlerFunction(element)
        }
      })
    },
    getApplicationsList () {
      this.applicationsList = this.$store.getters.getWidgetApps
      this.handleFormList()
    },
    /*
    async getRetrievebookingConfig (element) {
      let baseUrl = this.getBaseUrl(element)
      await axios.get(`${baseUrl}/configs/`)
        .then((response) => {
          element['data'] = response.data.response[0]
        })
        .catch((error) => {
          element['fail'] = true
          console.log('error get config backend', error)
        })
        .finally(() => {
          this.setLoadedTabs(element)
        })
    },
    */
    getSearchflightConfig (element) {
      this.$store.commit('setLoading', true)
      /// axios.get(this.$store.getters.getConfigUrls.apiUrl + 'configs/')
      this.$api.searchFlights.getConfig(this.$store.getters.getConfigUrls.apiUrl)
        .then((response) => {
          const enabledRoutes = {
            information: response.data.response.simple_routes.information,
            outgoin: response.data.response.simple_routes.routes,
            return: response.data.response.simple_routes.reverse_routes
          }
          let countrySetting = response.data.response.country_settings.find(x => x.default)
          this.bookingData = {
            roundTrip: response.data.response.journey_type.round_trip,
            roundTripDefault: response.data.response.journey_type.round_trip_default,
            oneWay: response.data.response.journey_type.one_way,
            oneWayDefault: response.data.response.journey_type.one_way_default,
            passengers: response.data.response.passenger_types,
            countrySettings: response.data.response.country_settings,
            countrySetting: countrySetting,
            display_logics: response.data.response.display_logics,
            routes: response.data.response.routes,
            cabin: response.data.response.cabin,
            cookieMessage: response.data.response.privacy_policy,
            temporaryMessage: response.data.response.temporary_message,
            promo: response.data.response.promo,
            session_key: this.$store.getters.getSessionId,
            searchParameters: response.data.response.search_parameters,
            tourcodeList: response.data.response.tourcode_list,
            route_pos_assign_data: response.data.response.route_pos_assign_data,
            enabledRoutes: enabledRoutes
          }
          element['data'] = this.bookingData
          this.setInitTemporaryMessage()
          // Norman Lo guardo para usarlo en FlightsResult
          this.$store.commit('setSearchFlightConfig', this.bookingData)
        })
        .catch((error) => {
          console.log('error get config backend', error)
          this.$router.push('internal-error')
        })
        .finally(() => {
          this.$store.commit('setLoading', false)
          this.setLoadedTabs(element)
        })
    },
    getBaseUrl (element) {
      return `${this.$store.getters.getConfigUrls.apiBaseUrl}${element.name}/api/v1`
    },
    isActiveTab (menuItem) {
      return this.activeItem === menuItem
    },
    setActiveTab (menuItem) {
      this.activeItem = menuItem
    },
    closeDiv (isTemporary) {
      if (isTemporary) {
        this.hasTemporaryMessage = false
      } else {
        this.bookingData.cookieMessage.enable = false
      }
    },
    setInitTemporaryMessage () {
      this.hasTemporaryMessage = this.bookingData.temporaryMessage.details.find(z => z.enable === true)
    },
    setLoadedTabs (element) {
      if (this.activeItem === element.name) {
        this.loadedTabs = true
      }
    },
    capitalize (string) {
      // esto deberia ir para algun helper
      return string.charAt(0).toUpperCase() + string.slice(1)
    }
  }
}
</script>

<style lang="sass" scoped>
@import '~core/assets/sass/double-buttons.sass'
.widgetBooking__main
  width: 100%
  max-width: 1400px
  margin: 0 auto
.widgetBooking__content
  display: flex
  flex-direction: column
.widgetBooking__tab
  display: flex
  flex-direction: row
  font-size: var(--font-size-sm)
  color: var(--color-onSurface)
  list-style: none
  padding: 0
  margin-bottom: 0
  overflow-x: auto
.widgetBooking__tabItem
  background-color: var(--color-surface)
  display: flex
  align-items: center
  white-space: nowrap
  &:first-child
    border-radius: var(--b-radius-sm) 0 0 0
  &:last-child
    border-radius: 0 var(--b-radius-sm) 0 0
  &.active
    .widgetBooking__tabLink
      background-color: transparent
      border-bottom: 2px solid var(--color-primary)
.widgetBooking__tabLink
  background-color: var(--color-overlay-05)
  padding: 0.5rem 2rem
  text-decoration: none
  color: var(--color-onSurface)
  cursor: pointer
  height: 100%
  width: 100%
  text-align: center
  box-sizing: border-box
.widgetBooking__tabContent
  background-color: var(--color-surface)
  border-radius: 0 0 var(--b-radius-sm) var(--b-radius-sm)
.tab-content > .active
  width: 100%
.embeddables
  min-height: 450px
@media screen and (min-width: 992px)
  .booking__title
    font-size: 2.5rem
  .embeddables
    display: block
    margin: 0 auto
    min-height: 200px
</style>
