<template>
  <div class="alertComponent container"
       :class="cookie === true ? 'alertComponent --bottom --warning' : 'alertComponent --top --primary'"
       >
    <div class="alertComponent__close" @click="closeDiv">
      <i
        class="fas fa-times"
        :id="cookie === true ? 'messageCloseBottom' : 'messageCloseTop'"
      ></i>
    </div>
    <div class="alertComponent__content">
<!--       <div class="alertComponent__icon" >
  <i class="fas fa-exclamation-triangle"></i>
</div> -->
      <slot name="container"></slot>
    </div>
    <div class="alertComponent__buttons">
      <button v-if="buttons_enable === true" class="btn btn-primary" @click="closeDiv">{{$t('accept')}}</button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AlertComponent',
  props: ['buttons_enable', 'cookie'],
  methods: {
    closeDiv () {
      this.$emit('close-alert-component')
    }
  }
}
</script>

<style scoped lang="sass">
.alertComponent
  color: var(--color-onSurface)
  background-color: var(--color-surface)
  padding: 0.75rem 1rem
  border-radius: var(--b-radius-md)
  display: flex
  flex-direction: column
  position: relative
  align-items: center
  // max-width: 1140px
  &.--bottom, &.--top
    position: fixed
    width: calc(100% - 1rem)
    left: 0
    right: 0
    margin: auto
    z-index: 1000
  &.--bottom
    bottom: .5rem
  &.--top
    top: 4rem
  &.--warning
    background-color: #fff3cd
    color: #856404
  &.--primary
    background-color: var(--color-primary)
    color: var(--color-onPrimary) !important
  &.--secondary
    background-color: var(--color-secondary)
    color: var(--color-onSecondary) !important
  &.--danger
    background-color: var(--color-error)
    color: var(--color-onError)
  &.--info
    background-color: #d1ecf1
    color: #0c5460
  i
    &.fa-times
      transition: opacity 0.2s ease
      opacity: 0.5
      cursor: pointer
      align-self: flex-start
      &:hover
        opacity: 1
.alertComponent__content
  flex-direction: row
  display: flex
  width: 100%
.alertComponent__close
  display: flex
  flex: 0
  justify-content: flex-end
  align-self: flex-end
  margin-bottom: 0.5rem
.alertComponent__message
  padding: 0
  flex: 1
.alertComponent__buttons
  padding: 0
  width: 100%
  justify-content: flex-end
  display: flex
.alertComponent__label
  display: flex
  align-items: center
  justify-content: flex-start
@media (min-width: 576px)
  .alertComponent
    flex-direction: column
  .alertComponent__buttons
    justify-content: flex-end
    .btn
      margin: 0 1rem 0 0
      &:last-child
        margin-right: 0
  .alertComponent__close
    margin: 0
  .alertComponent__message
    padding: 0 1rem
</style>
