<template>
  <div class="searchflight__formContainer">
    <!-- <tabs
      :booking-data="bookingData"
      :round-trip="roundTrip"
      @click-round-trip="setRoundTrip"
    ></tabs> -->
    <form
      autocomplete="off"
      @keypress.enter.prevent=""
      class="searchflight__form"
    >
      <!-- <div class="searchflight__inputgroup"> -->
      <div class="searchflight__formgroup --top">
        <FlightType
          :booking-data="bookingData"
          :round-trip="roundTrip"
          @click-round-trip="setRoundTrip"
        ></FlightType>
        <passengers
          :cabins="bookingData.cabin"
          :passengers="bookingData.passengers"
          :preSelected="defaultPassengers"
          v-model="passengers">
        </passengers>
      </div>
      <!-- </div> -->
      <div class="searchflight__formgroup --main">
        <autocomplete-airport
          v-model="origin"
          name="origin"
          :label="$t('origin')"
          icon-class="fas fa-plane"
          :airports="origins"
          :display="displayTarget"
        ></autocomplete-airport>
        <autocomplete-airport
          v-model="destination"
          :label="$t('destination')"
          name="destination"
          icon-class="fas fa-map-marker-alt"
          :airports="destinations"
          :display="displayTarget"
          @search-dates="getDatesAvailable"
        ></autocomplete-airport>
        <div class="datepickerInput__container">
          <div
            class="datepickerInput"
            :class="{'--disabled': dates === null}"
          >
            <div class="datepickerInput__group">
              <div class="datepickerInput__icon">
                <i class="fas fa-calendar-day"></i>
              </div>
              <!-- TODO Componentizar en simple/range picker-->
              <date-picker
                class="datepickerInput__input"
                v-if="roundTrip"
                ref="datepicker"
                v-model="dateRange"
                type="date"
                range
                placeholder=""
                :default-value="defaultDateOw"
                :lang="$store.getters.getLanguage"
                v-validate="'required'"
                :data-vv-as="$t('departure_date')"
                name="departure_date"
                id="departure_date_roundtrip"
                :disabled-date='isDisableDate'
                :title-format="'DD/MM/YYYY'"
                :format="'DD/MM/YYYY'"
                :editable="false"
                value-type="YYYY-MM-DD"
                :disabled="dates === null"
                @start-date="setStartDate"
                @close="startDate = null"
                @update:open="datePickerOpen"
              >
                <div slot="icon-calendar"></div>
              </date-picker>
              <date-picker
                class="datepickerInput__input"
                v-else
                v-model="dateRange"
                ref="datepicker"
                type="date"
                :default-value="defaultDateOw"
                placeholder=""
                :lang="$store.getters.getLanguage"
                v-validate="'required'"
                :data-vv-as="$t('departure_date')"
                name="departure_date"
                id="departure_date"
                :disabled-date='isDisableDate'
                :title-format="'DD/MM/YYYY'"
                :format="'DD/MM/YYYY'"
                value-type="YYYY-MM-DD"
                :editable="false"
                :disabled="dates === null"
                @update:open="datePickerOpen"
              >
                <div slot="icon-calendar"></div>
              </date-picker>
            </div>
            <div class="datepickerInput__feedback">
              {{ errors.first('departure_date') }}
            </div>
          </div>
        </div>
      </div>

      <div class="searchflight__formgroup --bottom">
        <country-settings
          :country-settings="bookingData.countrySettings"
          v-model="countrySetting"
        ></country-settings>
        <promo-code
          v-if="bookingData.promo.enable && bookingData.tourcodeList"
          :booking-data="bookingData"
          @addPromoCode="(val) => { this.promoCodeValue = val }"
        >
        </promo-code>
      </div>

      <div class="searchflight__searchBtn">
        <button
          tabindex="5"
          id="search_button"
          class="btn btn-primary"
          @click.prevent="submit"
          :disabled="errors.any()"
        >
          {{ $t('search') }}
        </button>
      </div>

    </form>
  </div>
</template>
<script>
import FlightType from './FlightType.vue'
import AutocompleteAirport from './AutocompleteAirport'
import CountrySettings from './CountrySettings'
import Passengers from './Passengers'
import PromoCode from './PromoCode'
// import Tabs from './Tabs'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import 'vue2-datepicker/locale/es'
import axios from 'axios'
import { pos } from './pos.js'

export default {
  name: 'SearchForm',
  components: {
    AutocompleteAirport,
    DatePicker,
    CountrySettings,
    Passengers,
    PromoCode,
    // Tabs,
    FlightType
  },
  inject: ['deviceType', '$validator'],
  props: {
    bookingData: {
      required: true,
      type: Object
    }
  },
  data () {
    return {
      countrySetting: '',
      idsAsigned: false,
      roundTrip: !this.bookingData.oneWayDefault,
      origin: '',
      destination: '',
      promoCodeValue: '',
      dateRange: null,
      passengers: '',
      startDate: null,
      dates: null,
      defaultPassengers: {
        counts: {
          adt: 1,
          chd: 0,
          inf: 0
        }
      }
    }
  },
  watch: {
    disabledDatepicker: {
      handler () {
        if (this.disabledDatepicker) {
          this.dates = null
          this.dateRange = null
        } else {
          this.getDatesAvailable()
        }
      }
    },
    roundTrip: {
      handler () {
        if (!this.disabledDatepicker) {
          this.getDatesAvailable()
        }
        this.idsAsigned = false
      }
    }
    /*
    '$i18n.locale': {
      handler () {
        this.changeKey++
      }
    }
    */
  },
  mounted () {
    this.pointOfSale = pos.init()
    this.webAssemblyTokenHandler()
    document.querySelectorAll('.v-dropdown-container')[0].id = 'originDropdownContainer'
    document.querySelectorAll('.v-dropdown-container')[1].id = 'destinationDropdownContainer'
  },
  methods: {
    webAssemblyTokenHandler () {
      let getWasmUrl = () => {
        return `${window.location.protocol}//${window.location.host}/wasm/`
      }
      let fetchPolifill = () => {
        let wasmPolyfillScript = document.createElement('script')
        wasmPolyfillScript.setAttribute('crossorigin', 'anonymous')
        wasmPolyfillScript.setAttribute('src', 'https://polyfill.io/v3/polyfill.min.js?features=blissfuljs%2Cdefault%2Ces2015%2Ces2016%2Ces2017%2Ces2018%2Ces5%2Ces6%2Ces7%2CBlob%2CArray.prototype.forEach')
        document.body.appendChild(wasmPolyfillScript)
      }
      let fetchWasmCss = () => {
        axios.get(`${window.WASM_URL}wasm.css`).then(res => {
          let wasmStyle = document.createElement('style')
          wasmStyle.setAttribute('rel', 'stylesheet')
          wasmStyle.setAttribute('type', 'text/css')
          wasmStyle.innerHTML = res.data
          document.body.appendChild(wasmStyle)
        })
      }
      let fetchWasmJs = () => {
        axios.get(`${window.WASM_URL}wasm_load.js`).then(res => {
          let wasmScript = document.createElement('script')
          wasmScript.innerHTML = res.data
          document.body.appendChild(wasmScript)
        })
      }
      window.navigator.userLanguage = this.$i18n.locale
      window.WASM_URL = getWasmUrl()
      fetchPolifill()
      fetchWasmCss()
      fetchWasmJs()
    },
    isDisableDate: function (date) {
      return !this.availableDates.includes(date.toISOString().split('T')[0])
    },
    datePickerOpen () {
      this.$nextTick(function () {
        if (!this.roundTrip) {
          const calendar = document.querySelector('.mx-calendar.mx-calendar-panel-date')
          calendar.id = 'firstDatePicker'
          calendar.querySelector('.mx-calendar-header').setAttribute('name', 'datePickerHeader')
          calendar.querySelector('.mx-calendar-content').setAttribute('name', 'datePickerContent')
        } else {
          const calendars = document.querySelectorAll('.mx-calendar.mx-calendar-panel-date')
          calendars[0].id = 'firstDatePicker'
          calendars[1].id = 'secondDatePicker'

          calendars.forEach(calendar => {
            calendar.querySelector('.mx-calendar-header').setAttribute('name', 'datePickerHeader')
            calendar.querySelector('.mx-calendar-content').setAttribute('name', 'datePickerContent')
          })
        }
      })
    },
    setRoundTrip: function (value) {
      if (this.roundTrip !== value) {
        this.roundTrip = value
        this.dateRange = null
      }
    },
    filterAirports: function (inputField) {
      let results = []
      let codes = []
      let allowedRoutes = this.bookingData.routes
      let oppositeField = inputField === 'origin' ? 'destination' : 'origin'
      if (this[oppositeField]) {
        allowedRoutes = allowedRoutes.filter(x => x[oppositeField].code === this[oppositeField])
      }
      allowedRoutes.forEach(x => {
        if (this.bookingData.searchParameters.findIndex(p => p.airport_code === x[inputField].code) >= 0) {
          x[inputField].description = `airport_name_${x[inputField].code}`
        }
        let airport = {
          name: `${x[inputField].code} - ${this.$t(x[inputField].description).toUpperCase()}, ${x[inputField].city.description} (${x[inputField].city.country.code})`,
          airport: x[inputField]
        }
        if (!codes.includes(x[inputField].code)) {
          results.push(airport)
          codes.push(x[inputField].code)
        }
      })
      return results
    },
    submit: function () {
      if (this.roundTrip && (!this.dateRange || !this.dateRange[0] || !this.dateRange[1])) {
        this.dateRange = null
      }
      let trips = {}
      this.$validator.validateAll().then(result => {
        if (result) {
          trips.departure = this.roundTrip ? this.dateRange[0] : this.dateRange
          trips.return = this.roundTrip ? this.dateRange[1] : null
          let search = {
            departure_date: trips.departure,
            origin: this.origin,
            destination: this.destination,
            adults: this.passengers.counts.adt,
            minors: this.passengers.counts.chd,
            infants: this.passengers.counts.inf,
            cabin: this.passengers.cabin,
            country_setting: this.countrySetting,
            return_date: trips.return,
            session_key: this.$store.getters.getSessionId,
            device_browser: this.pointOfSale.name,
            device_model: '???',
            device_id: '???',
            device_branding: '???',
            device_os_system_version: this.pointOfSale.os.version,
            device_os_system: this.pointOfSale.os.name,
            agent_preferred_language: navigator.language || navigator.userLanguage,
            device_category: window.mobilecheck() ? 'MOBILE' : 'DESKTOP',
            promo_code: this.promoCodeValue
          }
          search['wasm_token'] = window.getWasmToken(search)
          // search['wasm_token'] = null
          this.$store.commit('setLoading', true)
          axios.post(this.$store.getters.getConfigUrls.apiPost + '/action/', search).then((json) => {
            console.log('response json ' + json.data.response.session_key)
            this.$store.dispatch('goNextSpa')
          }).catch(function (error) {
            console.log('error spa Bakend ' + error)
          })
            .finally(() => {
              this.$store.commit('setLoading', false)
            })
        } else {
          this.$store.commit('setLoading', false)
        }
      })
    },
    setStartDate: function (date) {
      this.startDate = date.toISOString().split('T')[0]
    },
    getDatesAvailable: function () {
      const datePickerEl = this.$refs.datepicker.$el
      const progress = document.createElement('div')
      const indeterminate = document.createElement('div')
      progress.classList.add('fancyProgress')
      indeterminate.classList.add('indeterminate')
      progress.appendChild(indeterminate)
      datePickerEl.appendChild(progress)

      axios({
        method: 'get',
        url: this.$store.getters.getConfigUrls.apiUrl + 'dates/',
        params: {
          origin: this.origin,
          destination: this.destination,
          is_round_trip: this.roundTrip ? 1 : 0
        }
      })
        .then((response) => {
          console.log('response ' + response.data.response)
          this.dates = response.data.response
        })
        .catch(
          console.log('error api get dates'))
        .finally(() => {
          this.$refs.datepicker.$el.removeChild(this.$refs.datepicker.$el.children[this.$refs.datepicker.$el.children.length - 1])
        })
    }
  },
  computed: {
    displayTarget: function () {
      let deviceType = this.deviceType
      return this.bookingData.display_logics.find(x => x.device_type.toUpperCase() === deviceType)
    },
    availableDates: function () {
      let result = this.dates.ow
      if (this.startDate) {
        result = this.dates.rt
      }
      return result
    },
    origins: function () {
      return this.filterAirports('origin')
    },
    destinations: function () {
      return this.filterAirports('destination')
    },
    disabledDatepicker: function () {
      return !(this.origin && this.destination)
    },
    defaultDateOw: function () {
      return this.dates && this.dates.ow.length ? new Date(this.dates.ow[0]) : new Date()
    }
  }
}
</script>
<style lang="sass">
.autocompleteAirport
  &__label
    display: none
</style>
