<template>
  <div class="searchflights__promoCodeContainer">
    <div class="searchflights__promoCode">
      <div
        @click="openModal()"
        class="inputTag --sm"
      >
        <label class="inputTag__label" for="basic-url" v-if='!newPromoCode'>{{ $t('promo_code') }}</label>
        <p class="newPromoCode">{{ newPromoCode }}</p>
      </div>
    </div>
    <!--div> MODAL PROMO CODE </!--div-->
    <transition name="modalfadein" mode="in-out">
      <div v-if="modalOpen" class="selectInput__promoContainer">
        <div class="selectInput__promoCodeModal">
          <div class="selectInput__promoCodeModalHead">
            <label class="inputText__label" for="promo_code">
              {{ $t('promo_code') }}
            </label>
            <div @click="closeModal()" class="selectInput__promoCode_Close">
              <i class="fas fa-times"></i>
            </div>
          </div>
          <div class="selectInput__promoCodeModalBody">
            <div class="selectInput__promoCode">
              <form>
                <div
                  v-if="bookingData.promo.enable"
                  class="inputText"
                >
                  <div class="inputText__group">
                    <div class="inputText__icon">
                      <i class="fas fa-tag"></i>
                    </div>
                    <input
                      class="inputText__input"
                      id="promo_code"
                      name="promo_code"
                      aria-label="Promo Code"
                      aria-describedby="Promo Code"
                      tabindex="4"
                      autofocus
                      :maxlength="10"
                      v-model="newPromoCode"
                      @focus="promoCodeInvalid=false"
                    >
                  </div>
                  <span class="promoCodeError__feedback" v-if="promoCodeInvalid">
                    {{ $t('promo_code_invalid') }}
                  </span>
                </div>
              </form>
            </div>
            <button @click="addPromoCode" type="button" class="btn btn-primary"> {{ $t('continue') }} </button>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'promoCode',
  data () {
    return {
      newPromoCode: '',
      modalOpen: false,
      promoCodeInvalid: false
    }
  },
  methods: {
    addPromoCode: function () {
      if (this.validateCodeInserted()) {
        this.closeModal()
        this.$emit('addPromoCode', this.newPromoCode.toUpperCase())
      } else {
        this.promoCodeInvalid = true
      }
    },
    validateCodeInserted: function () {
      return this.bookingData.tourcodeList.findIndex((x) => {
        return x.tourcode_text === this.newPromoCode.toUpperCase()
      }) >= 0
    },
    closeModal: function () {
      if (this.promoCodeInvalid) {
        this.newPromoCode = ''
      }
      this.modalOpen = !this.modalOpen
    },
    openModal: function () {
      this.promoCodeInvalid = false
      this.modalOpen = !this.modalOpen
    }
  },
  props: {
    bookingData: { required: true, type: Object }
  }
}
</script>
