<template>
  <div class="container px-0">
    <div class="searchFlight">
      <Searcher
        v-if="bookingData"
        :bookingData="bookingData"
      />
      <!--<div v-if="bookingData && bookingData.cookieMessage.enable === true">
        <alertComponent v-show="showAlert"
          :buttons_enable="bookingData.cookieMessage.confirmation_button"
          :is_it_cookie="true"
          @close="showAlert = false"
        />
      </div>-->
      <div v-if="bookingData && bookingData.cookieMessage.enable === true">
        <cookie-message
          :buttons_enable="bookingData.cookieMessage.confirmation_button"
          @close-alert-component="closeDiv"
        ></cookie-message>
      </div>
      <div v-if="this.hasTemporaryMessage" >
        <temporary-message
          @close-alert-component="closeDiv(true)"
          :buttons_enable="bookingData.cookieMessage.confirmation_button"
          :details="bookingData.temporaryMessage.details"
        ></temporary-message>
      </div>
    </div>
  </div>
</template>

<script>

// import axios from 'axios'
import cookieMessage from '../components/cookieMessage'
import temporaryMessage from '../components/temporaryMessage'
import Searcher from 'core-lib/components/searcher/Searcher.vue'

export default {
  name: 'SearchFlight',
  data () {
    return {
      bookingData: null,
      showAlert: true,
      hasTemporaryMessage: false
    }
  },
  components: {
    Searcher,
    cookieMessage,
    temporaryMessage
  },
  mounted () {
    // axios.get(this.$store.getters.getConfigUrls.apiUrl + 'configs/')
    this.$api.searchFlights.getConfig(this.$store.getters.getConfigUrls.apiUrl)
      .then((response) => {
        const enabledRoutes = {
          information: response.data.response.simple_routes.information,
          outgoin: response.data.response.simple_routes.routes,
          return: response.data.response.simple_routes.reverse_routes
        }

        let countrySetting = response.data.response.country_settings.find(x => x.default)
        this.bookingData = {
          roundTrip: response.data.response.journey_type.round_trip,
          roundTripDefault: response.data.response.journey_type.round_trip_default,
          oneWay: response.data.response.journey_type.one_way,
          oneWayDefault: response.data.response.journey_type.one_way_default,
          passengers: response.data.response.passenger_types,
          countrySettings: response.data.response.country_settings,
          countrySetting: countrySetting,
          display_logics: response.data.response.display_logics,
          routes: response.data.response.routes,
          cabin: response.data.response.cabin,
          cookieMessage: response.data.response.privacy_policy,
          temporaryMessage: response.data.response.temporary_message,
          promo: response.data.response.promo,
          session_key: this.$store.getters.getSessionId,
          searchParameters: response.data.response.search_parameters,
          tourcodeList: response.data.response.tourcode_list,
          route_pos_assign_data: response.data.response.route_pos_assign_data,
          enabledRoutes: enabledRoutes
        }
        this.setInitTemporaryMessage()
        // Norman Lo guardo para usarlo en FlightsResult
        this.$store.commit('setSearchFlightConfig', this.bookingData)
      })
      .catch((error) => {
        console.log('error get config backend', error)
        // this.$router.push('internal-error')
      })
      .finally(() => {})
  },
  methods: {
    closeDiv (isTemporary) {
      if (isTemporary) {
        this.hasTemporaryMessage = false
      } else {
        this.bookingData.cookieMessage.enable = false
      }
    },
    setInitTemporaryMessage () {
      this.hasTemporaryMessage = this.bookingData.temporaryMessage.details.find(z => z.enable === true)
    }
  }
}
</script>
