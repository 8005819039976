const searchFlightsService = ({ api, promiseBuilder, getUrl }) => ({
  // GETS //////////////////////////////////////////////////
  getConfig: (url) => {
    const response = api.get(url + 'configs/')
    return response
  },

  getFlightsDates: (url, params) => {
    const response = api.get(`${url}/dates/`, params)
    return response
  },

  // POSTS //////////////////////////////////////////////////
  saveAction: (url, params) => {
    const response = api.post(`${url}/action/`, params)
    return response
  },
  eligibilitySetup: (url, params) => {
    const response = api.post(`${url}/eligibility_setup/`, params)
    return response
  }
})

export { searchFlightsService }
