<template>
  <div class="retrieveForm surface">
    <form
      :data-vv-scope="inScope"
      id="retrieveForm"
      name="retrieveForm"
      autocomplete="off"
      @keypress.enter.prevent=""
      class="retrieveForm__container"
    >
      <div class="" v-if="postMessageError">
        <div class="alert alert-danger">{{ $t(postMessageError) }}</div>
      </div>
      <div class="retrieveForm__group">
        <div
          class="inputText"
        >
          <label class="inputText__label">{{ $t('retrieve_booking_booking_code_label') }}</label>
          <div class="inputText__group">
            <div class="inputText__icon">
              <i class="fas fa-plane"></i>
            </div>
            <input
              class="inputText__input"
              :data-vv-as="$t('retrieve_booking_booking_code_label')"
              id="retrieve_booking_booking_code_label"
              name="retrieve_booking_booking_code_label"
              :placeholder="$t('retrieve_booking_booking_code_placeholder')"
              aria-label="Record Locator"
              aria-describedby="Record Locator"
              v-validate="'required|min:5|max:50'"
              :minlength="5"
              :maxlength="50"
              v-model="bookingCode"
              tabindex="1"
            >
          </div>
          <div class="inputText__feedback">{{ errors.first(`${inScope}.retrieve_booking_booking_code_label`) }}</div>
          <div class="inputText__helptext">
            <i class="fas fa-info-circle mr-1"></i>
            <span>{{ $t('retrieve_booking_tooltip_booking_code') }}</span>
          </div>
        </div>
        <div
          class="inputText"
        >
          <label class="inputText__label">{{ $t('retrieve_booking_last_name_label') }}</label>
          <div class="inputText__group">
            <div class="inputText__icon">
              <i class="fas fa-user"></i>
            </div>
            <input
              class="inputText__input"
              :data-vv-as="$t('last_name')"
              id="last_name"
              name="last_name"
              :placeholder="$t('retrieve_booking_last_name_placeholder')"
              aria-label="Last Name"
              aria-describedby="Last Name"
              :maxlength="50"
              v-validate="'required|max:50'"
              v-model="lastName"
              tabindex="2"
            >
          </div>
          <div class="inputText__feedback">{{ errors.first(`${inScope}.last_name`) }}</div>
          <div class="inputText__helptext">
            <i class="fas fa-info-circle mr-1"></i>
            <span>{{ $t('retrieve_booking_tooltip_last_name') }}</span>
          </div>
        </div>
        <div class="retrieveForm__searchBtn">
          <button
            tabindex="3"
            id="search_retrieve"
            class="btn btn-primary"
            @click.prevent="submit"
            @keyup.enter="submit"
            :disabled="errors.any(`${inScope}`) || !lastName || !bookingCode"
          >
            {{ $t('retrieve_booking_button') }}
          </button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import 'vue2-datepicker/index.css'
import 'vue2-datepicker/locale/es'
// import axios from 'axios'
import { pos } from './pos.js'
import { setError } from 'core/components/common/ErrorPages/errors'
import { get } from 'lodash'

export default {
  name: 'RetrieveForm',
  inject: ['deviceType', '$validator'],
  props: {
    bookingData: {
      required: false,
      type: Object
    },
    scope: {
      required: false,
      type: String
    },
    baseUrl: {
      required: true,
      type: String
    },
    externalData: {
      required: false,
      type: Object
    }
  },
  watch: {
    lastName (val) {
      this.lastName = val.replace(/[^a-zA-Z- ]+/g, '').toUpperCase()
    },
    bookingCode (val) {
      this.bookingCode = val.replace(/[^a-z0-9]/gi, '').toUpperCase()
    }
  },
  data () {
    return {
      bookingCode: null,
      lastName: null,
      inScope: null,
      postMessageError: null,
      moduleOrigin: null
    }
  },
  mounted () {
    this.setScope()
    this.pointOfSale = pos.init()
    if (this.externalData) {
      this.bookingCode = this.externalData.pnr
      this.lastName = this.externalData.surname
      this.moduleOrigin = this.externalData.moduleOrigin
      this.submit()
    }
  },
  methods: {
    setScope: function () {
      this.inScope = this.scope ? this.scope : this.$options.name
    },
    submit: function () {
      this.postMessageError = null
      this.$validator.validateAll(this.inScope).then(result => {
        if (result) {
          this.$store.commit('setLoading', true)
          let data = {
            point_of_sale: {
              user: this.$store.getters.getCarrier.toUpperCase(),
              session_key: this.$store.getters.getSessionId,
              device_browser: this.pointOfSale.browser.name,
              device_model: '???',
              device_id: '???',
              device_branding: '???',
              device_os_system_version: String(this.pointOfSale.os.version),
              device_os_system: this.pointOfSale.os.name,
              // comment this value not working in chrome browser
              agent_preferred_language: 'es-AR',
              device_category: window.mobilecheck() ? 'MOBILE' : 'DESKTOP'
            },
            record_locator: this.bookingCode,
            last_name_or_grp_name: this.lastName
          }
          /*
          if (this.bookingData) {
            // Por ahora esto sirve, solo, para webcheckin
            Object.assign(data['point_of_sale'], {
              kiu_device_id: this.bookingData.device_id,
              agent_id: this.bookingData.user_id,
              preferred_display_currency: this.bookingData.currency_code,
              country: this.bookingData.iso_country_code
            })
          }
          */

          if (this.$store.getters.getPosId) {
            data['forced_widget_pos'] = this.$store.getters.getPosId.posId
          }

          console.log(this.baseUrl)
          console.log(data)
          // let authorization = 'Token backendsearchflight.feac62088fa1_18335b9c4f304a7e9936ae310fb49882-dcd8994c79a299dacfa6e95a854b1fc23afdbd5d45bc8751'
          // let promise = axios.post(`${this.baseUrl}/action/`, data, { headers: { Authorization: authorization } })
          const payload = { state: this.$store.state, ...data }
          // let promise = axios.post(`${this.baseUrl}/action/`, payload)
          let promise = this.$api.searchFlights.saveAction(this.baseUrl, payload)
          promise.then((response) => {
            response = get(response, 'data', response)
            if (response.response.has_married_flights && !this.marriedFlightsAllowed) {
              this.postMessageError = this.$t('reservation_with_flight_from_another_carrier')
              this.$store.commit('setLoading', false)
            } else {
              if (!this.jjEcommerceSetup.mmb_group_pnr_allowed &&
                response.response.information?.booking_information?.record_locator_information?.pnr_group) {
                this.postMessageError = this.$t('group_reservation')
                this.$store.commit('setLoading', false)
              } else if (
                response.response.pnr_has_passenger_advice_code &&
                this.adviceCodeProcess &&
                this.wcTkAllowed
              ) {
                this.$store.dispatch('goOutsideIframe', {
                  path: 'bookingdetails',
                  query: {
                    exchange: true
                  }
                })
              } else {
                if (this.appName === 'webcheckin') {
                  let query = {}
                  if (this.moduleOrigin) {
                    query = {
                      moduleOrigin: this.moduleOrigin
                    }
                  }
                  this.$store.dispatch('goOutsideIframe', { path: 'travelinformation', query })
                } else {
                  this.$store.dispatch('goOutsideIframe')
                }
              }
            }
          }).catch((error) => {
            this.$store.commit('setLoading', false)
            if (this.externalData) {
              // redirect to url setting JJ ecommerce
              window.location.href = this.externalRedirect
            } else {
              this.handleError(error)
            }
          })
        } else {
          this.$store.commit('setLoading', false)
        }
      })
    },
    handleError (error) {
      let codeError = setError(error.response.data, 'res_error')
      if (error.response && error.response.data.extended.message) {
        this.postMessageError = codeError
      } else {
        if (get(error, 'response.data.message') && codeError === 'res_error_0') {
          this.postMessageError = this.$t(error.response.data.message)
        } else {
          this.postMessageError = this.$t('connection_backend_error')
        }
      }
      // if (error.response && error.response.data.extended.message) {
      //   this.postMessageError = (error.response.data.extended.message).replaceAll(' ', '_').toLowerCase()
      // } else {
      //   this.postMessageError = this.$t('connection_backend_error')
      // }
    }
  },
  computed: {
    externalRedirect () {
      return this.externalData.urlRedirect || this.$store.getters.getLogoUrlRedirect
    },
    appName: function () {
      return this.$store.state.systemStore.appName
    },
    appJJPrefix: function () {
      return this.appName === 'webcheckin' ? 'web_checkin' : 'mmb'
    },
    jjEcommerceSetup: function () {
      return this.$store.state.systemStore.generalSetting.ecommerce_setup
    },
    adviceCodeProcess: function () {
      return this.jjEcommerceSetup[`${this.appJJPrefix}_advice_code_process`]
    },
    marriedFlightsAllowed: function () {
      return this.jjEcommerceSetup[`${this.appJJPrefix}_allow_other_carrier_flights`]
    },
    wcTkAllowed: function () {
      return (this.appName === 'webcheckin' && this.jjEcommerceSetup.web_checkin_tk_allowed) || this.appName !== 'webcheckin'
    }
  }
}
</script>

<style lang="sass">
.retrieveForm
  padding: 1rem
  display: flex
  flex-direction: column
  width: 100%
  border-radius: 0 0 var(--b-radius-sm) var(--b-radius-sm)
  a
    color: var(--color-primary)
  &__container
    display: flex
    flex-wrap: wrap
    justify-content: flex-start
    flex-direction: column
  &__searchBtn
    width: 100%
    margin: 1rem 0
  .btn
    flex: 1
    width: 100%
    height: 3rem
  &__group
    display: flex
    justify-content: flex-start
    width: 100%
    flex-wrap: wrap
    .inputText
      width: 100%
  &__addon
    display: flex
    flex-direction: column
    border-top: 1px solid var(--color-overlay-10)
    padding-top: 1rem
    font-size: var(--font-size-sm)
.inputText__helptext
  display: flex !important
  align-items: baseline
@media (min-width: 768px)
  .retrieveForm
    &__group
      justify-content: space-between
      .inputText
        flex-basis: calc(50% - .5rem)
@media (min-width: 992px)
  .retrieveForm
    margin-bottom: 0
    &__group
      justify-content: flex-start
      width: 75%
      flex-wrap: nowrap
      .inputText
        flex: 1
        margin-right: 1rem
        margin-bottom: 0
    &__searchBtn
      width: auto
      flex: 1
      margin: 27px 0 1rem
</style>
