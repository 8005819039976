<template>
  <div class="inputSelect__container">
    <div class="inputSelect" @keydown.tab="checkInput()">
      <div class="inputSelect__label" :for="name">{{ label }}*</div>
      <div class="inputSelect__group">
        <div class="inputSelect__icon"><i class="fas" :class="iconClass"></i></div>
        <v-suggest class="inputSelect__input"
                   v-model="selected"
                   :ref="name"
                   :data="airports"
                   :delay="1"
                   :show="true"
                   v-validate="'required'"
                   :data-vv-as="label"
                   :name="name"
                   :id="name"
                   :maxLength="5"
                   align="right"
                   @values="notify"
                   @clear="$emit('input', '')"
                   :placeholder="$t(label)"
                   autoFocus="true"
        >
          <template #default="{ row }">
            <span class="airportResult">
              <strong v-if="display.airport_code" v-text="row.airport.code"></strong>
              <span v-if="display.airport_name" class="ml-1" v-text="$t(row.airport.description).toUpperCase()"></span>
              <strong v-if="display.city_code" class="ml-1" v-text="row.airport.city.code"></strong>
              <span v-if="display.city_name" class="ml-1" v-text="row.airport.city.description"></span>
              <strong v-if="display.country_code" class="ml-1" v-text="row.airport.city.country.code"></strong>
              <span v-if="display.country_name" class="ml-1" v-text="row.airport.city.country.description"></span>
            </span>
          </template>
        </v-suggest>
      </div>
      <span class="inputSelect__feedback">{{ errors.first(name) }}</span>
    </div>
  </div>
</template>

<script>
import { Suggest } from 'v-suggest'

export default {
  name: 'AutocompleteAirport',
  components: {
    'v-suggest': Suggest
  },
  inject: ['$validator'],
  props: {
    name: {
      required: true,
      type: String
    },
    label: {
      required: true,
      type: String
    },
    airports: {
      required: true
    },
    display: {
      required: true
    },
    value: {
      required: false
    },
    iconClass: {
      required: false,
      type: String
    }
  },
  data () {
    return {
      selected: ''
    }
  },
  methods: {
    checkInput: function () {
      if (!this.airports.find(x => x.name === this.selected)) {
        this.$refs[this.name].clear()
      }
    },
    notify: function (payload) {
      this.$emit('input', payload.airport.code)
    }
  }
}
</script>

<!-- <style lang="sass">
.v-dropdown-caller
  padding: 0
  width: 100%
.sg-results
  padding: 0 !important
ul.sg-results
  max-height: 150px !important
.sg-results__row
  padding: 10px !important
  border-bottom-style: solid
  border-bottom-color: var(--color-overlay-30)
  border-bottom-width: 1px
</style>

<style lang="sass" scoped>
.airportResult
  word-break: break-word
</style>

<style lang="sass">
.inputSelect
  margin-bottom: 1rem
.inputSelect__input
  div.v-suggest
    input[type=text]
      display: block
      width: 100%
      height: 3rem
      padding: .5rem 2rem .5rem 1rem
      margin-bottom: 0
      font-size: 1rem
      font-weight: 400
      line-height: auto
      position: relative
      color: var(--color-onSurface)
      background-color: var(--color-surface)
      background-clip: padding-box
      border: 1px solid var(--color-overlay-20)
      border-radius: var(--b-radius-sm)
      transition: box-shadow 0.2s ease, border-color .2s ease, background-color .2s ease
      -webkit-appearance: none
      &:hover
        background-color: var(--color-overlay-l-10)
        border-color: var(--color-overlay-30)
        box-shadow: 0 0 0 .2rem var(--color-overlay-10)
      &::placeholder
        color: var(--color-onSurface) !important
        opacity: .4 !important
      &:focus
        border-color: var(--color-primary)
        box-shadow: none
      &:focus
        &:hover
          border-color: var(--color-primary)
      &:disabled
        background-color: var(--color-overlay-10)
        color: var(--color-onSurface)
        cursor: not-allowed
        i
          opacity: .3
        &:hover
          background-color: var(--color-overlay-20)
        &:active
          box-shadow: none
          background-color: var(--color-overlay-20)
      // &:active
      //   box-shadow: none !important
      //   border-color: var(--color-primary)
      //   background-color: var(--color-surface)
      &[aria-invalid="true"]
        border-color: var(--color-error)
  & + .inputSelect__icon
    opacity: 1
div.v-suggest
  div.sg-clear
    font-size: 1rem !important
    right: 0 !important
    span
      display: none !important
    &::after
      content: '\f00d'
      display: flex
      align-items: center
      font-style: normal
      font-variant: normal
      text-rendering: auto
      line-height: 1
      font-family: 'Font Awesome 5 Free'
      font-weight: 900
      position: absolute
      top: 0
      right: 1rem
      bottom: 0
      z-index: 10
      opacity: 0.5
div.v-dropdown-container
  background-color: var(--color-surface)
  color: var(--color-onSurface)
  ul.sg-results
    li.sg-results__row, .sg-over
      color: var(--color-onSurface) !important
    li.sg-over
      background-color: var(--color-overlay-05) !important
      &:focus, &:active
        background-color: var(--color-overlay-20) !important
.inputSelect__icon
  position: absolute
  z-index: 5
  left: 0.5rem
  top: 0
  bottom: 0
  margin: auto
  height: 2rem
  width: 2rem
  opacity: 0.5
  display: flex
  align-items: center
  justify-content: center
  transition: opacity 0.2s ease
  & + .inputSelect__input
    div.v-suggest
      input
        padding-left: 2.75rem !important
.inputSelect__label
  font-size: var(--font-size-sm)
  font-weight: 700
  margin-bottom: 0.5rem
.inputSelect__group
  position: relative
  // min-height: 3rem
  &.--invalid
    .datepickerInput__input
      border-color: var(--color-error)
.inputSelect__feedback
  display: block
  margin-top: 0.5rem
  font-size: var(--font-size-xs)
  color: var(--color-error)
// Change background color on surface
.surface
  .inputSelect__input
    div.v-suggest
      input
        color: var(--color-onBackground)
        background-color: var(--color-background)
@media (min-width: 992px)
  .inputSelect__container
    flex-basis: 33%
    margin-right: 1rem
  .inputSelect__input
    div.v-suggest
      input
        padding: .5rem 4rem .5rem 1rem
</style> -->
