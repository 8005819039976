<template>
  <div></div>
</template>

<script>
import { pos } from '../components/pos.js'

export default {
  name: 'BookingExternal',
  inject: ['deviceType', '$validator'],
  props: ['params'],
  beforeCreate () {
    this.$store.commit('setAppName', this.$route.query.module)
  },
  mounted () {
    this.pointOfSale = pos.init()
    this.checkExternalCall()
  },
  methods: {
    async checkExternalCall () {
      const queryParams = this.$route.query
      try {
        let idParam = queryParams.id
        let queryData = null
        if (idParam) {
          queryData = this.getDataFromEncodedQParam(idParam)
        } else {
          if (queryParams.pnr && queryParams.surname) {
            queryData = queryParams
          }
        }
        this.$router.push({
          name: 'widget-booking',
          params: {
            externalData: queryData
          },
          query: {
            no_tabs: true,
            module: this.$route.query.module,
            carrier: this.$route.query.carrier
          }
        })
      } catch (e) {
        console.log('External call ' + e.toString())
        /* redirect to Q-Param url or url setting JJ Ecommerce */
        window.location.href = this.$route.query.urlRedirect || this.$store.getters.getLogoUrlRedirect
      }
    },
    getDataFromEncodedQParam: function (data) {
      let decodedData = JSON.parse(window.atob(data))
      if (typeof decodedData === 'object') {
        return decodedData
      } else {
        throw new Error('decoding data. ')
      }
    }
  }
}
</script>

<style scoped>

</style>
