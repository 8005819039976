import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { getI18n } from 'core/plugins/I18n'
require('core/plugins/VeeValidate.js')

let i18n = getI18n()
Vue.config.productionTip = false

let app = new Vue({
  i18n,
  router,
  store,
  render: h => h(App)
}).$mount('#app')

export { app }
