<template>
  <div class="selectInput_currency" v-show="showComponent">
    <div class="inputSelect"
         v-if="hasCountrySettings"
    >
      <!-- <span class="inputSelect__label" for="basic-url">{{ $t('country_settings') }}*</span> -->
      <div class="inputSelect__group --sm">
        <select
          class="inputSelect__input"
          ref="countrySettings"
          id="search-form__select-currency"
          @change="change"
          v-model="country"
        >
          <option
            v-for="option in countrySettingEnable"
            :value="option.id"
            :key="option.id"
          >
            {{ $t(`description_${option.iso_country_code}_${option.currency_code}_${option.device_id}`) }}
          </option>
        </select>

      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CountrySettings',
  props: {
    countrySettings: { required: true },
    value: { required: false, default: '' }
  },
  computed: {
    hasCountrySettings: function () {
      return this.countrySettings.length
    },
    countrySettingEnable: function () {
      return this.countrySettings.filter(x => x.enable)
    },
    showComponent: function () {
      return this.countrySettingEnable.length > 1
    }
  },
  data () {
    return {
      country: ''
    }
  },
  methods: {
    change: function () {
      this.$emit('input', this.countrySettingEnable.find(x => x.id === this.country))
    }
  },
  created () {
    this.country = this.countrySettingEnable.find(x => x.default).id
    this.change()
  }
}
</script>

<style lang="sass" scoped>
.inputSelect
  margin-bottom: 1rem
.inputSelect__input
  padding: 0 2.5rem 0 1rem
  width: 100%
  height: 2rem !important
  border: none
  color: var(--color-onSurface)
  border-radius: var(--b-radius-sm)
  overflow: hidden
  background-color: var(--color-overlay-05)
  -webkit-appearance: none
  outline: none
  position: relative
  z-index: 1
  width: 100%
  &:focus, &:hover, &:active
    box-shadow: var(--z-1)
    background-color: var(--color-overlay-10)
  &[aria-invalid="true"]
    border-color: var(--color-error)
  & + .inputSelect__icon
    opacity: 1
.inputSelect__group::after
  content: '\f078'
  display: flex
  font-style: normal
  font-variant: normal
  text-rendering: auto
  height: 1rem
  line-height: 1
  font-family: 'Font Awesome 5 Free'
  font-weight: 900
  position: absolute
  justify-content: center
  align-items: center
  top: 0
  right: 1rem
  bottom: 0
  margin: auto
  z-index: 10
  opacity: 0.5
.inputSelect__label
  font-size: var(--font-size-sm)
  font-weight: 700
  margin-bottom: 0.5rem
  display: block
.inputSelect__group
  position: relative
  &.--invalid
    .datepickerInput__input
      border-color: var(--color-error)
  &.--sm
    font-size: var(--font-size-sm)
.inputSelect__feedback
  display: block
  margin-top: 0.5rem
  font-size: var(--font-size-xs)
  color: var(--color-error)
@media (min-width: 992px)
  .inputSelect
    margin-left: 1rem
    margin-bottom: 0
  .inputSelect__input
    max-width: 200px
</style>
